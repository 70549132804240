<template>
  <div class="page-wrapper">
    <b-container fluid>
      <bo-page-title>
        <div class="ml-2">
          <router-link class="btn m-l-15 router-link-exact-active router-link-active btn-success" to="add-level"><i
            class="fas fa-plus mr-2"></i>
            Add New
          </router-link>
        </div>
      </bo-page-title>
      <b-card no-body>
        <b-card-header>
          <b-row>
            <b-col lg="5">
              <h5 class="card-title">User Level </h5>
            </b-col>
            <b-col lg="3">
              <b-form-group>
              <v-select placeholer="Select Status" v-model="selected_status" :options="status"></v-select>
              </b-form-group>
            </b-col>
            <b-col lg="4">
              <b-input-group>
                <b-form-input placeholder="Type keyword then enter..."></b-form-input>
                <b-input-group-append>
                  <b-button variant="success"><i class="fas fa-search"></i></b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>
        </b-card-header>
        <b-card-body class="p-0">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>#</th>
                <th>Level Name</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="user in users" :key="user.id">
                <td>{{user.no}}</td>
                <td>{{user.level_name}}</td>
                <td><span class="badge badge-success">{{user.status}}</span></td>
                <td>
                  <a href="#" class="icon_action"><i class="ti-marker-alt"></i></a>
                  <a href="#" class="icon_action"><i class="ti-settings"></i></a>
                  <a href="#" class="icon_action"><i class="ti-trash"></i></a>
                </td>
              </tr>
            </tbody>
          </table>
        </b-card-body>
      </b-card>
    </b-container>
  </div>
</template>
<script>
    import GlobalVue from '@/libs/Global.vue'
    export default {
    name: 'Article',
    extends: GlobalVue,
    components: {},
    data() {
      return {
        users: [{
            no: 1,
            level_name: 'Administrator',
            status: 'Active'
          },
          {
            no: 2,
            level_name: 'Content Writer',
            status: 'Active'
          },
          {
            no: 3,
            level_name: 'Editor',
            status: 'Active'
          },
          {
            no: 4,
            level_name: 'Director',
            status: 'Active'
          },
          {
            no: 5,
            level_name: 'Consultant',
            status: 'Active'
          },
          {
            no: 6,
            level_name: 'Blogger',
            status: 'Active'
          }
        ],
        selected: 'Y',
        options: [{
            item: 'Y',
            name: 'Active'
          },
          {
            item: 'N',
            name: 'Inactive'
          }
        ],
        selected_level: 'Administrator',
         levels: [
          'Administrator',
          'Content Writter',
          'Editor'
        ],
        selected_status: 'Active',
        status: [
        'Active',
        'Inactive',]
      }
    }
  }
</script>